.pageEl .tabbedElement ul.contentTabs {
	border-bottom: 1px solid $color-grey;
	margin-top: 0;
}

.contentTabs li {
	position: relative;
	bottom: -1px;
	border-bottom: 0;
	&:hover {
		border-bottom: 0;
	}
	&.selected {
		position: relative;
		border-bottom: 0;
		box-shadow: inset 0 1px 0 0 $color-grey,
					inset 1px 0 0 0 $color-grey,
					inset -1px 0 0 0 $color-grey;
		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 1px;
			right: 1px;
			height: 1px;
			background: white;

		}
	}
}

.contentTabs li span a {
	@include fontH5();
	text-transform: capitalize;
	color: $color-black;
}

.noTabs .contentTabs { display: none; }
