#underConstruction.user_mode .construction-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: url(https://cdn3.sportngin.com/attachments/photo/6862/4577/MNHockey-SplashPage__1_.jpg) center center no-repeat fixed #bfd4eb;
    background-size: contain;
    z-index: 9999999999999;
    top: 0;
    left: 0
}
#underConstruction.user_mode .construction-overlay > * {
    display: none;
}
body#underConstruction.user_mode {
    overflow:hidden;
}
