$color-blueLight: 	#00529b;
$color-blue: 		#0d375f;

$color-redLight: 	#df363e;
$color-red: 		#e3373f;
$color-redDark: 	#a91d23;

$color-lightBlueLight: 	#cbdcec;
$color-lightBlue: 		#8eb2d4;

$color-lightGrey: 	#eee;
$color-grey: 		#8899a6;
$color-darkGrey: 	#666;
$color-black: 		#333;

$color-bg: #bdd3eb;


$fontFamily-sans: 'Open Sans', helvetica, arial, sans-serif;
$fontFamily-condensed: 'Oswald', sans-serif;



@mixin fontBase() {
	font-family: $fontFamily-sans;
	font-weight: 400;
	font-size: 14px;
	line-height: 30px;
}

@mixin fontH1() {
	font-family: $fontFamily-sans;
	font-weight: 800;
	font-size: 36px;
	line-height: 48px;
}
@mixin fontH2() {
	font-family: $fontFamily-sans;
	font-weight: 800;
	font-size: 24px;
	line-height: 36px;	
}
@mixin fontH3() {
	font-family: $fontFamily-condensed;
	font-weight: 700;
	font-size: 24px;
	line-height: 36px;
}
@mixin fontH4() {
	font-family: $fontFamily-condensed;
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
}
@mixin fontH5() {
	font-family: $fontFamily-condensed;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}
@mixin fontH6() {
	font-family: $fontFamily-sans;
	font-weight: 700;
	font-size: 14px;
	line-height: 24px;
}

@mixin fontPullQuote() {
	font-family: $fontFamily-sans;
	font-weight: 600;
	font-size: 20px;
	font-style: italic;
	line-height: 30px;	
}

@mixin fontNav() {
	font-family: $fontFamily-condensed;
	font-size: 15px;
	line-height: 20px;
	text-transform: uppercase; 
}
@mixin fontSubnav() {
	font-family: $fontFamily-sans;
	font-size: 14px;
	line-height: 20px;
}
@mixin fontTimestamp() {
	font-family: $fontFamily-sans;
	font-style: italic;
	font-size: 12px; 
}