.aggregatorElement .readMore {
	display: none;
}

.newsAggregatorElement .item {
	border-top: 0 !important;
	border-bottom: 1px solid #ccc;
	border-image: none;
	padding: 10px 0;
	&:nth-of-type(1) { border-bottom: 1px solid #ccc; }
}

.aggHeader {
	@include fontH3();
	background: $color-blue;
	width: 100%;
	padding: 5px 10px;
	max-width: 100%;
	box-shadow: none;
}


.pageElement .aggHeader {
	color: white;
}
.pageElement.aggregatorElement h4 a {
	@include fontH6();
	color: $color-blue;
}

.newsAggregatorElement .newsAuthor,
.newsAggregatorElement .newsDate {
	@include fontTimestamp();
	color: $color-grey;
}

.condensed .teaser{
	text-transform: none;
}
