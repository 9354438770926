.headlineWrap {
	// @include linearGradient(180deg, transparent, $color-blue);
	background-image: linear-gradient(to bottom, transparent 25%, $color-blue 100%);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
.newsSlideShow-headline {
	padding: 0 50px 50px;
	top: auto;
	bottom: 0;
	left: 0;
	right: 0;
	h1 { padding: 0; margin: 0 0 10px; }
	p { padding: 0; margin: 0; }
	p span {
		background: none;
		color: $color-lightBlue;
		font-family: $fontFamily-sans;
		font-style: italic;
		font-size: 12px;
		line-height: 18px;
	}
	.newsSlideShow-more { float: none; clear: both; }
}
.newsSlideShow-headline h1 {
	background: transparent none;
	&:before { display: none; }
}
.newsSlideShow-headline .newsSlideShow-more {
	background: transparent none;
	border: 1px solid white;
	height: 30px;
	line-height: 28px;
	&:hover { border: 1px solid white; }
}

.newsSlideShow-prev.pill-small-left,
.newsSlideShow-next.pill-small-right {
	bottom: 40px;
	background: fade-out(black, .5);
	box-shadow: none;
	margin-bottom: 2px;
}
.newsSlideShow-prev.pill-small-left { margin-left: 2px; }
.newsSlideShow-next.pill-small-right { margin-right: 2px; }

.newsSlideShow-thumbnails {
	bottom: 40px;
	background: transparent;
	box-shadow: none;
}

.newsSlideShow-thumbnails a:before {
	background: $color-lightBlue;
	height: 12px;
	width: 12px;
	border: 2px solid $color-lightBlue;
	box-sizing: border-box;
}
.newsSlideShow-thumbnails a:hover:before, .newsSlideShow-thumbnails a.current:before {
	background: white;
	transform: scale(1, 1);
}









.sn-media-slider .slider .slide-description {
	font-style: italic;
}
