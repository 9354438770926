.linkElement h4 a {
	background: $color-lightGrey;
	color: $color-red;
	text-transform: capitalize;
	box-shadow: none;
	padding-right: 35px;
	&:hover {
		background: $color-red;
		color: white;
	}
}

.linkElement .goToLink {
	&:before,
	&:after {
		content: '';
		display: block;
		position: absolute;
		right: 10px;
		left: auto;
		top: 50%;
		transition: 200ms margin ease-in-out;
	}
	&:before {
		height: 6px;
		width: 6px;
		margin-top: -3px;
		background: $color-red;
		margin-right: 10px;
	}
	&:after {
		border-left: 10px solid $color-red;
		border-top: 10px dashed transparent;
		border-bottom: 10px dashed transparent;
		margin-top: -10px;
		margin-left: 5px;
	}
	&:hover {
		&:before { margin-left: 10px; background: white;}
		&:after { margin-left: 15px; border-left-color: white; }
	}
}

.heroText .linkElement {
	h4 a {
		@include fontH3();
		position: relative;
		display: inline-block;
		color: $color-blueLight;
		background: transparent none;
		box-shadow: none;
		padding: 10px 10px 10px 0;
		text-transform: capitalize;
		transition: color 200ms;
		&:hover {
			background: transparent none;
			color: $color-red;
		}
		&:before,
		&:after {
			// left: 100%; // cause offscreen bug
			right: auto;
			display: none; // fix to remove icons from custom styled link element pulled from a different site as a mirrored element
		}
		&:hover {
			&:before { background: $color-red; }
			&:after { border-left-color: $color-red; }
		}
	}
	.text {
		font-size: 14px;
		line-height: 1.71em;
		font-style: normal;
		background: transparent none;
		padding: 0;
	}
	.goToLink,
	.emailLink{
		&:before{
			display:none;
		}
		&:after{
			color: $accent-color;
			content: "\f061"; // Right Arrow
			font-family: FontAwesome;
			display:inline-block;
			line-height: .8em;
			margin: 0;
			margin:0 0 0 6px;
			padding:0;
			position: relative;
			transition: color 200ms;
			border: 0;
			top: -3px !important;
		}
		&:hover:after {
			color: lighten($accent-color, 10%);
		}
	}
}

.surveyLinkElement.pageElement {
	a {
		@include fontH3();
		background: transparent none;
		border: 0;
	}
	a span { color: $color-blue; }
	span small{ font-size: em(24); font-weight: 700; }
}


.surveyLinkElement.pageElement a {
	background: $color-lightGrey;
	color: $color-red;
	text-transform: capitalize;
	box-shadow: none;
	padding-right: 35px;
	&:hover {
		background: $color-red;
		color: white;
	}
}
.surveyLinkElement.pageElement a {
	&:before,
	&:after {
		content: '';
		display: block;
		position: absolute;
		right: 10px;
		left: auto;
		top: 50%;
		transition: 200ms margin ease-in-out;
	}
	&:before {
		height: 6px;
		width: 6px;
		margin-top: -3px;
		background: $color-red;
		margin-right: 10px;
	}
	&:after {
		border-left: 10px solid $color-red;
		border-top: 10px dashed transparent;
		border-bottom: 10px dashed transparent;
		margin-top: -10px;
		margin-left: 5px;
	}
	&:hover {
		&:before { margin-left: 10px; background: white;}
		&:after { margin-left: 15px; border-left-color: white; }
	}
}

.pageElement.surveyLinkElement {
	display: inline-block;
	width: auto;
	a span:after { display: none; }
	a {
		@include fontH3();
		position: relative;
		display: inline-block;
		color: $color-blueLight;
		background: transparent none;
		box-shadow: none;
		padding: 10px 10px 10px 0;
		text-transform: capitalize;
		&:hover {
			background: transparent none;
			color: $color-red;
		}
		&:before,
		&:after {
			left: 100%;
			right: auto;
		}
		&:hover {
			&:before { background: $color-red; }
			&:after { border-left-color: $color-red; }
		}
	}
	.text {
		font-size: 14px;
		line-height: 1.71em;
		font-style: normal;
		background: transparent none;
		padding: 0;
	}
}
