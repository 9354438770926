.blueMediaSlider {
	.slider .slide-title { 
		font-family: $fontFamily-sans; 
		font-weight: 900;
		font-size: 36px;
		line-height: 36px;
		text-transform: uppercase;
	}
	.slider .slide-description {
		font-family: $fontFamily-sans; 
		font-weight: 700;
		font-size: 16px;
		line-height: 2;
	}
	.slides .slide .slide-overlay {
		background: fade-out($color-blue, .4);
	}
	.slider-pagination {
		height: 32px; 
	}
	.slider-pagination.circular .paging-item {
		height: 12px;
		width: 12px;
		margin: 0 3px;
		background: $color-lightBlue;
		border: 2px solid $color-lightBlue;
		&:hover {
			background: white; 
		}
	}
}