.mnDateElement {
	.pageElement { margin-bottom: 30px; }
	.text {
		text-align: center;
		background: transparent url('../images/icon-mn.svg') center center no-repeat; 	
		background-size: 30px;
		font-family: $fontFamily-sans;
		font-weight: 700;
		color: $color-blue; 
	}
	p { 
		display: inline-block; 
		margin-bottom: 0; 
		font-weight: 900;
		line-height: 30px;
	}
	p:first-of-type { margin-right: 30px; }
	p:last-of-type { margin-left: 30px; }
}