// html {
// 	background: $color-bg url('../images/background.jpg') top center no-repeat;
// }
.home #topNav .theme-sub-nav {
    display: none;
}
html.nav-fixed {
	padding-bottom: 0;
}
.homeLogo {
	a {
		display: block;
		height: 260px;
		width: 414px;
		margin: 0 auto;
		background: transparent url('../images/logo-mnhockey-lined.png') center center no-repeat;
		background-size: contain;
	}
	.has-mobile-nav &{
		display: none;
		position: absolute;
		// top: 0;
		margin-top: -$account-nav-height-mobile;
		width: 100%;
		pointer-events: none;
		a{
			width: 120px; // 100%;
			height: 40px;
			background-size: contain;
			z-index: -1;
			margin: 2px 0;
			pointer-events: auto;
		}
	}
	.has-mobile-nav.page-manager-visible &{
    width: calc(100% - 60px);
	}
}
html.has-mobile-nav #accountNav{
	position: absolute;
	bottom: 0;
	top: auto;
	margin-top:auto;
}
html.has-mobile-nav body{
	padding-bottom: 44px;
}
.snFooterContainer .container {
    width: 100%;
}

body {
	@include fontBase();
}

h1, h2, h3, h4, h5, h6 {
	color: $color-blueLight;
}
.text, .previewText, .newsText{
  > h3{
		color: $color-red;
	}
}

a {
	color: $color-blueLight;
}

#panelTwo {
	padding-top: 10px;
}

.pageElement > h3 {
	@include fontH3();
	color: $color-blueLight;
}

#siteContainer #displayBodyHeader:not(.displayBodyHeader-image) {
	color: $color-blueLight;
	font-family: $fontFamily-sans;
	font-weight: 900;
	text-transform: uppercase;
	padding: 0 ($element-layout-spacing * 2) 15px; // 0 25px 15px;
	margin-bottom: 10px; // 20px;
	border-bottom: 1px solid $color-grey;
}
.newsSlideShow-container {
    margin-bottom: -2.5rem; // negate overflow, may overlap other elements, if so delete
}
.textBlockElement > h3:only-child{
	margin-bottom: 0;
}
.mediaSlider{
	h2, p{
		&:empty{ display: none;}
	}
}

.mobile-home-logo{
	display: none;
}
.has-mobile-nav{
	.mobile-home-logo{
		width: 100%;
		padding-left: $account-nav-height-mobile;
		height: $account-nav-height-mobile;
		display: flex;
		flex-flow: row;
		justify-content: center;
		align-items: center;
		a{
			width: 120px;
			height: 40px;
			background: transparent url('../images/logo-mnhockey-lined.png') center center no-repeat;
			background-size: contain;
		}
	}
}