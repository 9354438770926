.custom-cta{
	.sn-call-to-action {
		padding-top: 3px;
		&:before {
			height: 3px;
			border-bottom: 0;
		}
		&:after { display: none; }
	}

	.sn-call-to-action .sn-call-to-action-overlay {
		top: auto;
		height: auto;
		padding: 14px 20px;
		background: fade-out(white, .1);
	}

	.sn-call-to-action .sn-call-to-action-title {
		@include fontH3();
		color: $color-blue;
		font-size: 20px;
		line-height: 22px;
		padding: 0;
	}

	.sn-call-to-action.sn-variation-slide-up .sn-call-to-action-overlay {
		background: fade-out(white, .1);
	}
	.sn-call-to-action .sn-call-to-action-subtitle a,
	.sn-call-to-action .sn-call-to-action-subtitle span {
		color: $color-blueLight;
	}
}
