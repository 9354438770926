.sponsorBar {
	$sponsorElWidth: 145px;
	position: relative;
	padding: 0 59px;
	margin-bottom: 20px;
	.window { overflow: hidden; }
	.column {
		padding: 0;
		transition: 200ms all ease-in-out;
	}
	.pageEl {
		width: $sponsorElWidth;
		padding: 0 2px;
		float: left;
	}
	.pageElement {
		margin-bottom: 0;
	}
	.prev, .next {
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: 99;
		width: 20px;
		cursor: poiter;
	}
	.prev {
		left: 20px;
		background: transparent url('../images/icon-prev.svg') center center no-repeat;
	}
	.next {
		right: 20px;
		background: transparent url('../images/icon-next.svg') center center no-repeat;
	}
}






.sponsored {
	// margin-top: 10px;
	.textBlockElement {
		> h3 {
			position: relative;
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: space-between;
			padding-top: 30px;
			span {
				display: block;
			}
		}
		.theSponsor {
			display: inline-block;
			height: 60px;
			margin-left: 50%;
			img{
				height: 100%;
				width: auto !important;
			}
		}
		.sponsorImage {
	    flex-flow: row wrap;
	    align-items: center;
	    right: 0;
	    bottom: 0;
			display: block;
	    min-width: 200px;
	    position: relative;
			&:before {
				// font-family: $fontTwo;
				content: 'Presented by';
				display: block;
				line-height: 1;
				color: #333;
				font-size: 12px;
				margin: 4px 10px 4px 0px;
				font-weight: 400;
				text-transorm: none;
		    position: absolute;
				left: 0;
		    bottom:initial;
			}
		}
	}
}


.sponsored h3 > span {
    // display: block;
}

.sponsored .textBlockElement .sponsorImage {
    // display: block;
    // min-width: 200px;
    // position: relative;
}

.sponsored .textBlockElement .theSponsor {
    // margin-left: 50%;
}

.sponsored .textBlockElement .sponsorImage:before {
    // left: 0;
    // bottom:initial;
}
