@mixin linearGradient($startpoint, $c1, $c2) {
    background-color: $c1;
    background-image: -webkit-linear-gradient($startpoint, $c1, $c2);
    background-image:    -moz-linear-gradient($startpoint, $c1, $c2);
    background-image:     -ms-linear-gradient($startpoint, $c1, $c2);
    background-image:      -o-linear-gradient($startpoint, $c1, $c2);
    background-image:         linear-gradient($startpoint, $c1, $c2);
}

@mixin clearfix() {
	&:before, 
	&:after {
		content: '';
		display: table;
	}
	&:after {
		clear: both; 
	}
}