#survey_steps {
  .vevent {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .dateImage {
    width: 75px;
    float: left;
    margin-right: 10px;
    list-style-type: none;
    text-align: center;

    .month {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 14px;
      background-color: $link_color;
      color: #fff;
    }
    .date {
      font-size: 48px;
      line-height: 1;
      background-color: #eee;
      font-weight: 700;
    }
  }
  .summary {
    font-size: 18px;
  }
  .status {
    font-weight: bold;
  }
  .details {
    list-style: none;
  }
  .description {
    font-size: 12px;
  }
  .surveyButtonWrap {
    margin: 0;
  }
}
.secureCheckoutMessage {
  margin-bottom: 1.5rem;
}
#tstRegistrationHeader h1 {
  background: url(https://assets.ngin.com.s3.amazonaws.com/site_files/676/siteHeader.jpg);
  height: 150px;
}
#tstRegistrationHeader {
  height: 150px;
}
#surveyNameHeader h1 {
  background-image: none;
}
