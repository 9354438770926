.tableElement {
	td, th { 
		border: 0; 
		background: transparent none;
	}
	th { text-transform: capitalize; }
	thead { background: $color-blue; }
	tbody td { background: transparent none; }
	tr { border-bottom: 1px solid #9db4cc; }
	tr.odd { background: #ebebeb; }
	.dataTable { 
		border: 1px solid #9db4cc;
		border-collapse: collapse; 
	}
}