.snFooterContainer {
	max-width: none;
	background: $color-blue url('../images/bg-footer.jpg') top center;
	background-size: cover;
}
#siteFooter {
	padding: 0;
	> ul {
		text-align: center;
	}
	li {
		color: white;
		vertical-align: middle;
	}
	li a { color: $color-lightBlue; }
	.footerNewsletterWrap {
		padding: 20px 0;
		background: $color-red;
		p {
			line-height: 1;
		}
		a {
			font-size: 22px;
			font-family: $fontFamily-sans;
			font-weight: 400;
			color: white;
		}
	}
	//
	.footerNewsletterWrap {
    max-height:60px;
    transition:max-height 300ms linear;
		&.newsletterOpen {
			max-height:1000px;
			.mailSubscribeElement {
				 opacity:1;
			 }
		}
		.mailSubscribeElement {
			border: none;
			padding: 0;
			opacity: 0;
			transition:opacity 300ms;
			-webkit-transition-delay: 100ms;
			transition-delay: 100ms;
			h3 {display:none;}
			th {
				font-family: "Oswald", sans-serif;
				font-size: 20px;
				font-weight: 400;
				color: $color-blueLight;
			}
			.resultsTable {
				margin: auto;
			}
			> form {
				padding-top: 20px;
			}
		}
		.pageElement input[type="text"],
		.pageElement input[type="password"] {
			width: 200px;
			border: 1px solid #e4e0e0;
			border-radius: 3px;
			font-size: 16px;
		}
		td a {
			background: white;
			padding: 5px;
			color: $color-blueLight !important;
			font-family: $fontFamily-condensed;
			border-radius: 4px;
			font-size: 16px !important;
		}
	}
	//
	.footerLogoWrap {
		padding-top: 50px;
		.pageElement {
			width: 226px;
			margin: 0 auto;
		}
		margin-bottom: 30px;
		img { border: 0; }
	}
	.footerSocialWrap {
		text-align: center;
		margin-bottom: 20px;
	}
	.footerNavWrap {
		// @include clearfix();
		margin-bottom: 60px;
		text-align: center;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-around;
		align-items: center;
		align-content: center;
		max-width: 1000px;
		margin: 0 auto 60px auto;

		.pageElement {
			display: inline-block;
			padding: 0 10px;
			text-align: left;
			@media screen and (max-width: 600px) { width: 50%; }
			@media screen and (max-width: 400px) { width: 100%; }
			h3 {
				margin-bottom: 0;
				font-size: 16px;
				font-family: $fontFamily-sans;
				color: $color-lightBlue;
			}
		}
		p {
			margin-bottom: 0;
			font-size: 14px;
	    line-height: 1.5em;
		}
		a {
			font-weight: 400;
			color: white;
		}
	}
}
#siteFooter li#poweredByNGIN a:after { display: none; }
#siteFooter li#poweredByNGIN a { margin: 0 auto; }
#siteFooter li#poweredByNGIN a img { display: block; margin-top: 3px; }
