#topNav {
	.theme-main-nav {
		@include linearGradient(0deg, $color-redDark, $color-redLight);
		border-bottom: 0;
	}
	.theme-main-nav .theme-nav > .theme-nav-item > .theme-nav-link {
		@include fontNav();
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.theme-main-nav .theme-nav > .theme-nav-item.selected > .theme-nav-link {
		background: $color-red;
	}

	.theme-nav-style-dropdown > .theme-nav-title a {
		color: $color-blue;
		font-weight: 700;
	}
	.theme-nav-style-dropdown > .theme-nav-item.hasChild > .theme-nav-link:before {
		display: none;
	}
	.theme-main-nav .theme-nav > .theme-nav-item > .theme-nav-link:not(x):hover {
		background: $color-red;
	}
	.theme-sub-nav .theme-nav > .theme-nav-item > .theme-nav-link:not(x):hover {
		background: #8eb2d4;
	}
}

#topNav {
	.theme-search-wrapper {
		background: $color-blueLight;
	}
	.theme-search-bar .theme-search-submit {
		background: $color-blue;
	}
}


#topNav {
	.theme-sub-nav {
		background: $color-lightBlueLight ;
	}
	.theme-sub-nav .theme-nav > .theme-nav-item > .theme-nav-link {
		@include fontSubnav();
		font-weight: 600;
		color: $color-blue;
		padding-top: 10px;
		padding-bottom: 10px;
	}
}





#topNavPlaceholder {
	margin-bottom: 6px;
	border-bottom-color: transparent;
}
.site-tagline-text{ display: none !important; }
.affiliation{
	margin-right: auto;
	margin-left: 10px;
	text-transform: uppercase;
	letter-spacing: .5px;
	p{ margin: 0; }
	.usa-hockey-logo{
		margin-left: 10px;
		display: inline-flex;
		text-indent: 100%;
		white-space: nowrap;
		overflow: hidden;
		width: 70px;
		height: 35px;
		background: url(../images/usahockey-bug.png);
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
	}
}

.socialWrapper {
	min-width: 300px;
	text-align: right;
}
.socialList {
	display: inline-block;
	.pageElement {
		line-height: 0;
		margin: 2px 15px;
	}
}
.socialWrapper .sn-social-media-list .sn-social-media-icon {
	background: transparent none;
	border: 2px solid white;
	height: 1.1em;
	width: 1.1em;
	opacity: .5;
	&:hover {
		opacity: 1;
		background: transparent none;
	}
}
